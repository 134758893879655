//
// Auth.js
// - ------------------------------------------------------
// - Functions to support the Authentication
// - ------------------------------------------------------
//
const Airtable = require('airtable');
const base = new Airtable({apiKey: 'keyCv10aSA2Vxn4Ch'}).base('appKMHuPgfJLToeN1');

// getUrlVars
// Get variables from a Url formatted like https://page.html?var1=foo&var2=bar
export function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      vars[key] = value;
  });
  return vars;
}

// checkUrlParam
// Check if the url has uuid param
export function checkUrlParam() {
  const data = getUrlVars();
  if (data.uuid) { // is there a uuid? If so, insert userName, userEmail, and userFlower
    console.log("FFFF");
    const uuid = decodeURIComponent(data.uuid);
    localStorage.setItem('uuid', uuid);
    base('users').find(uuid, function(err, record) {
      if (err) { console.error(err); return false; }
      console.log("record",record);
      console.log('userName', record.fields['Name']);
      console.log('userEmail', record.fields['Email']);
      localStorage.setItem('userName', record.fields['Name']);
      localStorage.setItem('userEmail', record.fields['Email']);
      localStorage.setItem('userFlower', record.fields['flower']);
    });
  }
}


export function getUUID() {
  const uuid = localStorage.getItem('uuid');
  return uuid;
}

// isSignedIn
// Check if the local storage has uuid. If yes, return true.
export function isSignedIn() {
  const uuid = localStorage.getItem('uuid');
  console.log('uuid', uuid);
  if (uuid){
    return true;
  } else {
    return false;
  }
}

// signIn
// Sign user in based on their username or uuid
export function signIn(username) {
  base('users').select({
      view: 'Grid view'
  }).firstPage(function(err, records) {
    if (err) {
      console.error(err);
      $('#signin_error').show();
      return; }
    records.forEach(function(record) {
      if (record.fields["Name"] === username){
        localStorage.setItem('uuid', record.fields.uuid);
      }
    });
  });
}

// signOut
// Clear local storage.
export function signOut() {
  localStorage.clear();
}
