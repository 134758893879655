//
// Navigation.js
// - ------------------------------------------------------
// - Functions to support the Healify Wellness side navigation
// - ------------------------------------------------------
//
import { isSignedIn, signOut } from "./auth.js";

$(document).ready(function(){
  var isSigned = isSignedIn();
  if (isSigned){
    $('#signed-out-menu').hide();
    $('#signed-in-menu').show();
    $('#secondary-sign-in').hide();
    $('#secondary-sign-out').show();
  }
});
